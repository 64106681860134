<template>
  <CommonDialog ref="dialog" :title="title" :type="dialogType" @confirm="confirm">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px" label-suffix="：">

      <el-form-item label="所属部门" prop="deptId">
        <TreeSelect
            :options="options"
            :value="formData.deptId"
            @getValue="handleDeptSelect"
        />
      </el-form-item>

      <el-form-item label="用户角色" prop="roleId" v-if="formData.id !== 1">
        <el-select v-model="formData.roleId" placeholder="请选择角色" @change="roleSelectChange">
          <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户名" prop="nickname">
        <el-input v-model="formData.nickname"></el-input>
      </el-form-item>

      <el-form-item label="登录账号" prop="username">
        <el-input v-model="formData.username"></el-input>
      </el-form-item>

      <el-form-item label="手机号码" prop="phone">
        <el-input v-model="formData.phone"></el-input>
      </el-form-item>

      <!--<el-form-item label="电子邮箱" prop="email">-->
      <!--  <el-input v-model="formData.email"></el-input>-->
      <!--</el-form-item>-->

    </el-form>

  </CommonDialog>
</template>

<script>
import CommonDialog from "@/components/CommonDialog.vue";
import {validateEMail, validatePhone} from "@/utils/validate";
import TreeSelect from "@/components/TreeSelect/TreeSelect.vue";

export default {
  name: "CurDialog",
  components: {TreeSelect, CommonDialog},
  data() {
    return {
      title: '',
      filterText: '',
      formData: {
        deptId: null,
        roleId: null,
        nickname: '',
        username: '',
        phone: '',
        //email: '',
      },
      popoverShow: false,
      options: [],
      dialogType: '',
      rules: {
        deptId: [
          {required: true, trigger: ['change'], message: '请选择所属部门'},
        ],
        roleId: [
          {required: true, trigger: 'blur', message: '请选择用户角色'},
        ],
        nickname: [
          {required: true, trigger: 'blur', message: '请输入用户名'},
          {min: 1, max: 10, message: '用户名称必须1-10个字符之间', trigger: "blur"}
        ],
        username: [
          {required: true, trigger: 'blur', message: '请输入登录账号'},
          {min: 6, max: 20, message: '账号必须6-20个字符之间', trigger: "blur"}
        ],
        phone: [
          {required: true, trigger: 'blur', message: '请输入手机号码'},
          {required: true, validator: validatePhone, message: '请慎输入正确的手机号码', trigger: "blur"}
        ],
        //email: [
        //  {required: false, validator: validateEMail, message: '请慎输入正确的电子邮件', trigger: "blur"}
        //]
      },
      roleOptions: [],
    }
  },
  methods: {
    confirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          this.$message.warning("请填写完整")
          this.$refs.dialog.stopLoading()

        }else {
          if (this.formData.id == null) {
            this.$api.user.save(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$refs.dialog.close()
              this.$emit('confirm')
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })

          } else {
            this.$api.user.update(this.formData).then(() => {
              this.$message.success('保存成功')
              this.$refs.dialog.close()
              this.$emit('confirm')
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        }
      })
    },

    show(id = null, dialogType = null) {
      this.reset()
      this.dialogType = dialogType
      if (dialogType === this.$gc.dialogType.ADD) {
        this.toAdd()
      } else if (dialogType === this.$gc.dialogType.EDIT) {
        this.toEdit(id)
      }

      this.getRoleList()
      this.getDeptTree()

      this.$refs.dialog.show()
    },

    toAdd() {
      this.title = '添加'
      this.dialogType = 'success'
    },

    toEdit(id) {
      this.title = '编辑'
      this.dialogType = 'primary'
      // 查询数据
      this.getData(id)
    },

    handleDeptSelect(val) {
      this.$set(this.formData, 'deptId', val)
    },

    getData(id) {
      this.$api.user.getById(id).then(res => {
        this.formData = res
      })
    },

    getDeptTree() {
      this.$api.dept.tree().then(res => {
        this.options = res
      })
    },

    getRoleList() {
      this.$api.role.list().then(res => {
        this.roleOptions = res
      })
    },

    roleSelectChange(val) {
      this.formData.roleId = val
    },

    reset() {
      this.$resetForm('form')
      this.options = []
    }
  }
}
</script>

<style scoped lang="scss">

.tag-group {
  min-height: 40px;
  background: #ffffff;
  border: #d7d8da solid 1px;
  border-radius: 5px;

  max-height: 1000px;
  //transition: max-height 1s linear;
}

</style>
